@font-face {
	font-family: "SFProDisplay-Regular";
	src: url("../assets/fonts/SFProDisplay-Regular.woff2") format("woff2"),
		url("../assets/fonts/SFProDisplay-Regular.woff") format("woff"),
		url("../assets/fonts/SFProDisplay-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "SFProDisplay-Medium";
	src: url("../assets/fonts/SFProDisplay-Medium.woff2") format("woff2"),
		url("../assets/fonts/SFProDisplay-Medium.woff") format("woff"),
		url("../assets/fonts/SFProDisplay-Medium.ttf") format("truetype");
}

@font-face {
	font-family: "SFProDisplay-Semibold";
	src: url("../assets/fonts/SFProDisplay-Semibold.woff2") format("woff2"),
		url("../assets/fonts/SFProDisplay-Semibold.woff") format("woff"),
		url("../assets/fonts/SFProDisplay-Semibold.ttf") format("truetype");
}

@font-face {
	font-family: "SFProDisplay-Bold";
	src: url("../assets/fonts/SFProDisplay-Bold.woff2") format("woff2"),
		url("../assets/fonts/SFProDisplay-Bold.woff") format("woff"),
		url("../assets/fonts/SFProDisplay-Bold.ttf") format("truetype");
}

.SFProDisplay-Regular {
	font-family: "SFProDisplay-Regular";
}

.SFProDisplay-Medium {
	font-family: "SFProDisplay-Medium";
}

.SFProDisplay-Semibold {
	font-family: "SFProDisplay-Semibold";
}

.SFProDisplay-Bold {
	font-family: "SFProDisplay-Bold";
}

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

*:focus {
	outline: none !important;
	box-shadow: none !important;
}

html {
	height: 100%;
}

body {
	font-family: "SFProDisplay-Regular";
	min-height: 100%;
	height: 100%;
}

textarea {
	resize: none;
}

.table-responsive::-webkit-scrollbar {
	display: none !important;
}

.table-responsive {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

#root {
	height: 100%;
}

.cursor-default {
	cursor: default;
}

.cursor-pointer {
	cursor: pointer;
}

.rounded-5 {
	border-radius: 0.5em !important;
}

.rounded-8 {
	border-radius: 0.8em !important;
}

.rounded-10 {
	border-radius: 1em !important;
}

.rounded-20 {
	border-radius: 2em !important;
}

.rounded-30 {
	border-radius: 3em !important;
}

.rounded-40 {
	border-radius: 4em !important;
}

.rounded-50 {
	border-radius: 5em !important;
}

.search-icon {
	padding: 17px;
	padding-left: 55px;
	width: 300px;
	border: 1px solid #f5f5f5;
	font-size: 13px;
	color: gray;
	background-image: url("../assets/images/search.svg");
	background-repeat: no-repeat;
	background-position: left center;
	outline: 0;
}

.custom-cell {
	max-width: 200px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	vertical-align: middle;
}

.dropdown-toggle::after {
	content: none;
}

.dropdown-no-hover {
	background-color: #fff !important;
	color: #000 !important;
}

.white-space-nowrap {
	white-space: nowrap;
}

.modal-content {
	border: none;
	border-radius: 0.5rem;
}

.nav-item {
	padding-right: 2em;

	.nav-link {
		padding-left: 0;
		padding-right: 0;
		color: #000;
		border: none;

		&.active {
			color: #2c71c3;
			border-bottom: 2px solid #2c71c3;
		}
	}
}

.page-item {
	padding: 0.5rem !important;

	&.active {
		.page-link {
			border-bottom: 2px solid #2c71c3 !important;
		}
	}

	.page-link {
		padding: 0.1rem !important;
	}
}

.text-overflow-ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.border-none {
	border: none !important;
}

.bg-none {
	background: none !important;
}

.custom-accordion {
	padding: 1rem 0;

	&:not(.collapsed)::after {
		content: none !important;
	}

	&::after {
		content: none !important;
	}

	&:not(.collapsed) {
		box-shadow: none;
	}

	&:not(.collapsed)::before {
		background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
		transform: rotate(-180deg);
	}

	&::before {
		flex-shrink: 0;
		width: 0.8rem;
		height: 0.8rem;
		content: "";
		background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
		background-repeat: no-repeat;
		background-size: 0.8rem;
		transition: transform 0.2s ease-in-out;
	}
}
