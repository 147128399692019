.menu {
	top: 1em !important;
	/* right: auto !important;
	left: 50% !important;
	-webkit-transform: translate(-50%, 0) !important;
	-o-transform: translate(-50%, 0) !important;
	transform: translate(-50%, 0) !important; */
}

.toggle {
	&::after {
		content: none;
	}
}

/* .dropdown-item:hover, .dropdown-item:focus {
	color: #000;
	background-color: #fff;
} */
