$theme-colors: (
  "primary": #2c71c3,
  "secondary": #f2f2f2,
  "light": #f6f8fa,
  "success": #ddf5c7,
  "danger": #f2eaea,
  "warning": #f6f3de,
  "info": #eaf1f9,
  "grey": #d4d5d7,
  "dark": #414451,
  "dark-green": #2d671f,
  "dark-red": #7a2c2c,
  "dark-yellow": #9c8d00,
);

$font-size-base: 0.9rem;

$accordion-button-active-bg: #fff;
$accordion-button-active-color: #000;
$accordion-button-padding-x: 1rem;
$accordion-icon-width: 0.8rem;
$accordion-padding-x: 2rem;
$accordion-body-padding-y: 0.5rem;
$accordion-body-padding-x: 1rem;

$dropdown-link-hover-color: #fff;
$dropdown-link-hover-bg: #2c71c3;
$dropdown-link-active-color: #000;
$dropdown-link-active-bg: #fff;
$dropdown-link-hover-color: #000;
$dropdown-link-hover-bg: #fff;

$pagination-border-width: 0;
$pagination-color: #000;
$pagination-hover-bg: none;
$pagination-active-bg: none;
$pagination-active-color: #2c71c3;

$nav-pills-border-radius: 0;
$nav-pills-link-active-bg: none;

$form-check-input-checked-bg-color: #2c71c3;

$input-group-addon-bg: #fff;

@import "node_modules/bootstrap/scss/bootstrap";
